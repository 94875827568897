/*------- 11. About style ------ */

.welcome-content {
  h5 {
    font-weight: 500;
    font-size: 16px;
    color: #666;
    margin: 0;
    letter-spacing: 0.3px;
  }
  h1 {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.3px;
    margin: 8px 0 30px;
    color: #433f3f;
    position: relative;
    display: inline-block;
    padding: 0 0 23px;
    line-height: 1;
    &:before {
      position: absolute;
      background-color: #070508;
      height: 3px;
      width: 70px;
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
    }
  }
  p {
    font-size: 16px;
    color: #4b4b4b;
    line-height: 30px;
    width: 66%;
    margin: 0 auto;
    @media #{$lg-layout} {
      width: 80%;
    }
    @media #{$md-layout} {
      width: 95%;
    }
    @media #{$xs-layout} {
      width: 100%;
      font-size: 15px;
    }
  }
 }

/* about page */

.single-count {
  .count-icon {
    i {
      font-size: 50px;
      line-height: 1;
      display: inline-block;
      @media #{$md-layout} {
        font-size: 45px;
      }
      @media #{$xs-layout} {
        font-size: 45px;
      }
    }
  }
  h2 {
    & > span {
      color: $theme-color;
      font-size: 40px;
      font-weight: bold;
      margin: 25px 0 12px;
      display: inline-block;
      @media #{$md-layout} {
        margin: 10px 0 8px;
        font-size: 35px;
      }
      @media #{$xs-layout} {
        margin: 6px 0 8px;
        font-size: 35px;
      }
    }
  }
  span {
    color: #333;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 500;
  }
}

.team-wrapper {
 
  .team-img {
    position: relative;

    img {
      position: relative;
      @media #{$md-layout} {
        left:180%;
      }
      @media #{$lg-layout} {
        left:180%;
      }
      @media #{$xl-layout} {
        left:180%;
        
      }
      @media #{$xx-layout} {

      
        left:180%;}
      
      @media #{$xxx-layout} {
        left:180%;}
      width: 100%;
    
    }
 
  }
  .team-content {
    position: relative;
    @media #{$md-layout} {
      left:180%;
    }
    @media #{$lg-layout} {
      left:180%;
    }
    @media #{$xl-layout} {
      left:180%;
    }
    @media #{$xx-layout}{
      left:180%;
    }
    @media #{$xxx-layout} {
      left:180%;}

    padding: 20px 20px 23px;
    h4 {
      font-size: 18px;
      font-weight: 500;
      margin: 0 0 3px;
      text-transform: capitalize;
    }
    span {
      font-size: 15px;
      font-style: italic;
    }
    p {
      font-size: 18px;
      color: black;
    }
  }

  .team-content2 {
    
    @media #{$md-layout} {
      width: 600px;
    }
    @media #{$lg-layout} {
      width: 600px;
    }
    @media #{$xl-layout} {
      width: 600px;
    }
    @media #{$xx-layout}{
      width: 600px;
    }
    
    p {
      font-size: 18px;
    }
    @media #{$xxx-layout} {
      left:180%;
      width: 600px;
    }
  }
}

.single-mission {
  h3 {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    margin: 0 0 20px;
    text-transform: capitalize;
    line-height: 22px;
    color: #333;
    @media #{$xs-layout} {
      margin: 0 0 10px;
    }
  }
  p {
    text-align: center;
    margin: 0;
  }
}

.about-brand-logo {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}
