.search-result-modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    //background-color: rgba(0, 0, 0, 0.4);
  }
  
  .search-result-modal.show {
    display: block;
  }
  
  .search-result-modal-content {
    width: 70%;
    padding: 20px;
    border-radius: 5px;
    display: block;
    margin: 0 auto;
    margin-top: 10px;
    box-shadow: 0 0 20px 2px rgba(0,0,0,.3019607843);
    max-height: 70vh;
    overflow: scroll;
    overflow-x: hidden;
    position: absolute;
    background: #fff;
    z-index: 999;
    right: 0;
    left: 0;
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
  }
  
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .searchedProducts{
    width: 100%;
    vertical-align: top;
    display: block;
    margin-bottom: 10px;
    clear: both;
  }

.searchedProductHeader{
        font-size: 1.5em;
        font-weight: 700;
        margin-bottom: 10px;
        text-transform: capitalize;
        color: #2e4145;
    
}

.searchedProductAmount{

        background: #2e4145;
        color: #fff;
        border-radius: 32px;
        height: 28px;
        width: auto;
        min-width: 27px;
        display: inline-block;
        text-align: center;
        vertical-align: text-top;
        padding: 0;
        padding-top: 1px;
        line-height: 26px;
        font-size: .6em;
        font-weight: 400;
    
}

.VueCarousel {
    display: flex;
    flex-direction: column;
    position: relative;
}

.VueCarousel-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.VueCarousel-inner {
    display: flex;
    flex-direction: row;
    backface-visibility: hidden;
    transform: translate(0px, 0px);
    transition: transform 0.5s ease 0s;
    flex-basis: 261.5px;
    visibility: visible;
    height: auto;
}

.VueCarousel-slide {
    flex-basis: inherit;
    flex-grow: 0;
    flex-shrink: 0;
    user-select: none;
    backface-visibility: hidden;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
}

.prodContainer{
    box-shadow: 0 0 5px 0 rgba(0,0,0,.2117647059);
    border-radius: 4px;
    margin: 11px;
    border: 1px solid rgba(46,65,69,.4784313725);
    min-height: 300px;
    transition: all .1s ease-in-out;
    cursor: pointer;
    padding: 14px;
    min-height: 400px;
    width: fit-content;

}

.prodImg :hover {
    box-shadow: 0 0 10px 0 rgba(0,0,0,.5215686275);
    transform: scale(1.02);
    background: #f6f6f6;
    transition: all 0.5s ease-in-out;
}

.blogContainer:hover{
    box-shadow: 0 0 10px 0 rgba(0,0,0,.5215686275);
    transform: scale(1.02);
    background: #f6f6f6;
    transition: all 0.5s ease-in-out;
}


.prodImg {
    display: inline-block;
    width: 100% ;
    vertical-align: top;
}

.prodInfo{
    width: 100%;
    display: inline-block;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif!important;
}

.prodInfoHeader{
    color: #2e4145;
    min-height: 42px;
}

.prodInfoText{
    font-size: 12px;
    margin: 0;
}

.searchedBlogs{
    // width: 100%;
    // vertical-align: top;
    // display: block;
    // margin-bottom: 10px;
    // clear: both;
    display: flex;
    // flex-direction: row;
    // flex-wrap: nowrap;
    justify-content: space-between;
}

.blogContainer{
    // margin-right: 25px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.2117647059);
    // background: #f2f2f2;
    // font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif!important;
    // border-radius: 4px;
    // width: 100%;
    // display: block;
    // vertical-align: top;
    margin: 11px;
    // min-height: 206px;
    // cursor: pointer;
    // transition: all .1s ease-in-out;
    // overflow: hidden;
}

.paginator {
    background-color: red;
  }

.blogInfo{
    min-height: 90px;
    background: white;
    margin-top: -4px;
    padding: 7px;
    width:100%;
}


.blogInfoHeader{
    font-size: .8em;
    font-family: inherit;
    padding: 6px;
    padding-bottom: 0;
    color: black;
}

.blogInfo h6 {
    color: black!important;
    text-decoration: underline;
    padding-left: 6px;
}

.blogInfoText{
    color: black;
    width:94%;
    padding: 6px;
    padding-top: 0;
    font-family: inherit;
    

}


@media (max-width: 800px) {
    .prodContainer {
      width: 100%;
        img{
        width:120px;
        height: 120px;
    }
    }

    .blogContainer{
        width:auto;
    }

    .blogInfo{
        width:100%;
    }

    .blogImg {
        img{
            width:100%;
        }

    }
    .search-result-modal-content{
        width:100%;
    }
  }