.InterestsCheckBoxContainer {
    width: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;

}

.Interests {
    width: 20px;
}

.InterestsColumn2 {
    position: relative;
    top: 0px !important;

    @media #{$xs-layout} {
        margin-left: 45px;
    }

}

.Interests-text {
    position: relative;
    margin-left: 15px;
    bottom: 15px;

    @media #{$xs-layout} {
        margin-left: 10px;
        font-size: 14px;
    }

}

.form-input {
    margin-bottom: 10px;
}

.input-wrapper {
    position: relative;
}

.form-input {
    padding-right: 24px;
    /* Make room for the eye icon */
}

.eye-icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}