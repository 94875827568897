.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width:30%;
}
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width:100%;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-body {
  margin-top: 10px;
}

.password-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: space-around;
    align-items: center;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pPop{
  padding-left: 20px;
  font-weight: 500;
  color:red;
}

.bespoke-consultation-button{
  margin:15px;
  padding:15px;
  border: none;
  background-color: $theme-color;
  cursor: pointer;
  color: white; 
  border-radius: 30px;
}

.bespoke-consultation-button-selected{
  margin:15px;
  padding:15px;
  border: black;
  cursor: pointer;
  color: black; 
  border-radius: 30px;
  background-color: yellow;
}


.popup-interests-checkbox-container {
 
  display: flex;
  width: 100%;
  line-height: 20px;;
  input[type=checkbox] {
    width: 20px;
    height: auto;
  }
  label {
    margin-left: 5px;
    margin-bottom: 0;
  }
}

.closeBtntop{
  color: red;
  font-size:24px;
  position: relative;
  bottom:25%;
  border: none;
  background: none;
  left:45%;
}

.formContainerComplited{

  background-color: white;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 350px;
  border-radius: 30px;
}

.form-complited-button{
  background-color: $theme-color;
  border-radius: 30px;
  color: white;
  border: white;
  padding:15px;
  transition: all 0.3s ease-in-out;
  &:hover{
    color:black;
  }

}

.popup-interests-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}


.popup-interests-column2 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.checkbox-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sub-popup-consultation {
  width: 100%;
  margin-bottom: 20px;
  & input {
    width: 30px;
  }
  & label {
    margin-bottom: 0;
    font-size: 20px;
  }
}

.containerpop {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}


.columnPOP {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  height: 50%;
}

.h5Pop {
  font-size: 20px;
  font-weight: 500;
  padding-top: 20px;
  padding-left: 20px;
}

.subform-name-email {
  display: flex; 
  width: 100%;
  #username{
    margin-right: 5px;
  }
  #email {
    margin-left:5px;
  }
}

.subform-interests {
  text-align: left;
  font-size: 18px;
  width: 100%;
  margin-bottom: 12px;
  margin-top: 15px;
}

input[type="radio"] {
  margin-right: 10px;
  width: 10%;
  margin-top: -10px;
}

.overlayPOP {
  position: fixed;
  bottom: 0;
  right: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 100;

}

.formContainer {
  background-color: #fff;
  z-index: 100;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-y: none;
  position: relative;

}

.formContainer2 {
  height: 104px;
  width: 250.706px;
  position: relative;
  top: 120px;
  left: 95px;
  transform: rotate(-45deg);
  transform-origin: left top;
  background-color: yellow;
  word-wrap: break-word;
}

.form {
  z-index: 100;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
  align-content: flex-start;
}

.overlayPOP .alert{
  padding:0;
  margin:0;
}

.subform-buttons-container {
  display: inline-block;
}

.popupsubbutton {
  margin-top: 10px;
  margin-right: 5px;
  background-color: #04AA6D;
  color: white;
  padding: 0 16px;
  font-size: 20px;
  border: none;
  border-radius: 30px;
}

.popupsubbutton2 {
  margin-left: 5px;
  margin-top: 10px;
  background-color: red;
  color: white;
  padding: 0 16px;
  font-size: 20px;
  border: none;
  border-radius: 30px;
}

.closeBtn {
  font-size: 16px;
  z-index: 100;
  position: absolute;
  top: 0;
  width: auto;
  height: 30px;
  border-style: none;
  background-color: transparent;
  border-radius: 50%;
  right: 0;
  color: #aaaaaa;
  float: right;
  font-weight: bold;
  margin-bottom: 5px;
}


.closeBtn:hover{
  color: black;
}

.closeBtn2 {
  font-size: 30px;
  z-index: 100;
  position: absolute;
  top: 0;
  width: 60px;
  height: 30px;
  border-style: none;
  background-color: transparent;
  border-radius: 50%;
  right: 0;
  color: #04AA6D;
}

.subform-selected-options {
  margin-top: 25px;
  max-width: 350px;
}

.selected-options-heading {
  margin-right: 5px;
}

.selected-option {
  /* Add your preferred styling here, for example: */
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: #f1f1f1;
  padding: 2px 6px;
  border-radius: 4px;
  display: inline-block;
}

@keyframes ExitAnimation {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes ExitAnimationButtom {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(50px);
  }
}



.formContainer  {
  /* entering styles */
  animation: ExitAnimation 0.7s ease-in-out forwards;
}



.closeBtn2 {
    /* existing styles */
  animation: ExitAnimationButtom 0.5s ease-out forwards;
}


@media #{$xs-layout} {
  .checkbox-container {
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .InputDiv {
    flex-direction: column;
    align-items: flex-start;
  }

  .columnPOP {
    margin-top:20px;
    //margin: 10px 0;
    height: auto;
  }

  input[type="radio"] {
    width: auto;
    margin-right: 5px;
  }

  .formContainer {
    height: auto;
    max-height: none;
  }


  .closeBtn {
    width: 25px;
    height: 25px;
  }

  .closeBtn2 {
    font-size: 20px;
    width: 60px;
    height: 30px;
  }
}

