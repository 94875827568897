/* components/product/OffPageFilter.js */

.open-filter-button {
    transition: all 0.3s ease 0s;
    cursor: pointer;
    font-size: 16px;
    line-height: 48px;
    display: block;
    width: 100% !important;
    height: 48px;
    text-align: center;
    text-transform: capitalize;
    border: none;
    background: none;

    color: #ffffff;
}