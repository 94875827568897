// Font Family
$poppins: "Poppins", sans-serif;
$abrilfatface: "Poppins", sans-serif;
$cormorantgaramond: "Poppins", sans-serif;
$greatvibes: "Poppins", sans-serif;
$lobster: "Poppins", sans-serif;
$josefin: "Poppins", sans-serif;

// text Colors ---------------

$white: #ffffff; //this var control the background color only (not includeing headers and footer)
$black: #000000;
$grey: #f6f7f8;
$red: #f6f7f8;
$green: #f6f7f8;

// bg Colors ---------------

$bg-white: #c5e093;
$bg-black: #000000;
$bg-grey: #f6f6f8;
$bg-purple: #f0e0ff;

// Heading Color
$heading-color: #000000;

// Theme Color
$theme-color: #04AA6D;
$theme-color-hover: #11ce89;

// Responsive Variables
$xxx-layout: "only screen and (min-width: 1601px) and (max-width: 3000px)";
$xx-layout: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$xl-layout: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs-layout: "only screen and (max-width: 767px)";
$sm-layout: "only screen and (min-width: 576px) and (max-width: 767px)";
$xxs-layout: "only screen and (min-width: 320px) and (max-width: 479px)";


/*
.header-padding-2 .row {
    align-items: center;
    align-content: middle;
    justify-content: center;
}
*/


a {
    text-decoration:none !important;
    color:$black;
    :hover{
        color:$theme-color;
    }
  }


@media only screen and (max-width: 1290px)
{
    .stick .logo img {
        width: 250px;
        height: auto;
    }
}

