.overlaypopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  
  .hidden {
    display: none;
  }
  
  .popup {
    background: linear-gradient(90deg, #bdfeb2, #a2c3ff);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index:11;
    width:500px;
    height:370px;

  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .popUpH2 {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
  
  .popUpP {
    font-size: 1.2rem;
  }
  
  .close-btn {
    background-color: transparent;
    border: none;
    color: red;
    font-size: 1.5rem;
    cursor: pointer;
    align-self: flex-end;
    z-index: 12;
  }


  .Welcome-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  .Welcome-popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    //width:49%;
    //height: 55%;
    background-color: white;
    overflow: auto;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
      width: 80%;
      //height: 25%;

    }
  }
  
  .Welcome-popup {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  
    .Welcome-iframe {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  
  .Welcome-close-button {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10001;

    @media (max-width: 768px) {
      //top: -15px;
      //right: -15px;
      width: 30px;
      height: 30px;
  
      .Welcome-close-button-line {
        width: 20px;
        height: 2px;
      }
    }
  }
  
  .Welcome-close-button-line {
    width: 30px;
    height: 2px;
    background-color: black;
    transform: rotate(45deg);
    position: absolute;
    @media (max-width: 768px) {
      width: 20px;
      height: 2px;
    }
  }
  
  .Welcome-close-button-line:nth-of-type(2) {
    transform: rotate(-45deg);
  }
  
  .Welcome-popup-close{
    position: fixed;
    bottom: 0;
    left: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 100;
  }

.Welcome-pop-up-close-contaienr {
    height: 150px;
    width: 250.706px;
    position: relative;
    top: -25px;
    left: -10px;
    transform: rotate(45deg);
    transform-origin: left top;
    background-color: purple;
  }