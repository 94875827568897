.homepage-product-tabs a.active h4 {
    font-size: 24px;
    font-weight: 500;
    background: $theme-color;
    color: white;
    padding: 15px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    animation: fadein 0.2s;
    border-radius: 30px;

}

.homepage-product-tabs a h4 {
    cursor: pointer;
    font-size: 20px;
    padding: 15px;
    border-radius: 30px;
}

.homepage-product-tabs a.active {
    border-bottom: none;
}

.homepage-product-tabs a {
    border-bottom: none;
    padding: 0;
    margin: 0;
}

.bouncingArrow{
        position: absolute;
        bottom: 20px;
        left: 45%;
        transform: translateX(-50%);
        z-index: 99;
        text-align: center;
        cursor: pointer;
        animation: 2s ease 0s infinite normal none running bounce;
        @media #{$xs-layout} {
               left: 25%;
          }
}

