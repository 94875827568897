/*------ 25. My account Page------*/
.errorMessage {
  color: red;
}


.return-button{
  font-size: 14px;
  font-weight: 500;
  border-radius: 30px;
  display: block;
  margin-bottom: 20px;
  padding: 16px 40px 17px;
  background-color: #fff;
  &:hover{
      color: #fff !important;
      border: 1px solid $theme-color;
      background-color: $theme-color !important;

  }

}
.sZQpId5n {
  background: #fff;
  border: 1px solid #e0e0e1;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
  flex-direction: column;
}

.haIP8Xvp {
  align-items: center;
  background: $theme-color;
  border-bottom: 1px solid #e0e0e1;
  border-radius: 6px 6px 0 0;
  color: #505050;
  color: #ffff;
  display: block;
  display: flex;
  flex-direction: row;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Fira Sans,Helvetica Neue,Arial,sans-serif;
  font-size: .875rem;
  font-style: inherit;
  font-style: normal;
  font-weight: inherit;
  font-weight: 400;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 150%;
  margin: 0;
  padding: 10px 20px;
  text-transform: uppercase;
  width: 100%;
}

.oCVmHQRG {
  flex: 1;
}

.OlEItjl_ {
  align-items: center;
  display: flex;
  flex: 1;
}

.qiBy4Tg9 {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 26px;
}

.MWaE6spp {
  align-items: center;
  display: flex;
  flex: 1;
  white-space: nowrap;
  width: 50%;
}

.PocxfICA {
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #e0e0e1;
  color: #404040;
  display: flex;
  padding: 15px 20px;
  width: 100%;
}
.MWaE6sppvieworder {
  display: flex !important;
flex-direction: column;
align-items: flex-start;
width:100%;
position: relative;
left:-21px;
}

.orderSummary{
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  width:100%;
  position: relative;
}


@media only screen and (max-width: 797px) {
  .MWaE6sppvieworder {
    display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  width:100%;
  position: relative;
  left:5px;
  }
  
  .PocxfICAvieworder {
  flex-direction: column;
  align-items: flex-start;
  padding:0;
  width:100%;
  border-bottom: 2px solid #ccc;
  }

  .product-pricevieworder{
    margin-top:7px;
    margin-left: 0 !important;
    margin-bottom: 7px;
    padding:7px;
    position: relative;
    //right:20px;
    border:1px solid green;
    border-radius: 30px;
    width:40%;
  }
  }

.oCVmHQRG {
  flex: 1;
}
.qiBy4Tg9 {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 26px;
}

.MWaE6spp {
  align-items: center;
  display: flex;
  flex: 1;
  white-space: nowrap;
  width: 50%;
}

.single-my-account {
  margin-bottom: 20px;
  border: 1px solid #ebebeb;
  .panel-heading {
    padding: 0;
    & > button {
      background: none;
      border: none;
      width: 100%;
      text-align: left;
      padding: 0;
    }
  }
  .card-body {
    border-bottom: 1px solid #ebebeb;
  }
  h3.panel-title {
    background-color: #f9f9f9;
    border-bottom: 1px solid #ebebeb;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
    color: #242424;
    display: block;
    padding: 16px 40px;
    position: relative;
    @media #{$xs-layout} {
      line-height: 22px;
      font-size: 14px;
      padding: 16px 30px 16px 40px;
    }

    &:hover {
      color: $theme-color;
    }
    &::before {
      color: #000;
      content: "\f107";
      display: inline-block;
      font-family: "FontAwesome";
      font-size: 16px;
      position: absolute;
      right: 10px;
      top: 19px;
    }
    &:hover::before {
      color: $theme-color;
    }

    span {
      color: #242424;
      font-size: 15px;
      left: 20px;
      position: absolute;
      top: 16px;
    }
  }
  .myaccount-info-wrapper {
    padding: 30px 20px;
    background-color: #fff;
    .account-info-wrapper {
      border-bottom: 1px solid #eaeaea;
      margin-bottom: 28px;
      padding-bottom: 30px;
      h4 {
        font-size: 15px;
        margin: 0;
        text-transform: uppercase;
      }
      h5 {
        font-size: 16px;
        letter-spacing: 0.2px;
        margin-top: 7px;
      }
    }
    .billing-info {
      margin-bottom: 20px;
      label {
        color: #000;
        font-size: 14px;
        text-transform: capitalize;
      }
      input {
        background: transparent none repeat scroll 0 0;
        border: 1px solid #ebebeb;
        color: #000;
        height: 40px;
        padding: 0 15px;
      }
    }
    .billing-back-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 26px;
      align-items: center;
      .billing-back {
        a {
          color: #000;
          display: inline-block;
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;
          &:hover {
            color: $theme-color;
          }
          i {
            font-size: 16px;
            color: $theme-color;
          }
        }
      }
      .billing-btn {
        button {
          background-color: #f2f2f2;
          border: medium none;
          color: #000;
          display: inline-block;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          padding: 16px 35px 17px;
          text-transform: uppercase;
          cursor: pointer;
          transition: all 0.3s ease 0s;
          &:hover {
            background: $theme-color;
            color: #fff;
          }
        }
      }
    }
    .entries-wrapper {
      border: 1px solid #eaeaea;
      position: relative;
      @media #{$xs-layout} {
        padding: 30px 10px;
        &::before {
          display: none;
        }
      }
      &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 1px;
        left: 50%;
        top: 0;
        background-color: #eaeaea;
      }
      .entries-info {
        padding: 30px 20px;
        @media #{$xs-layout} {
          padding: 0 10px 30px;
        }
        p {
          color: #000;
          font-size: 15px;
          margin: 0;
          text-transform: capitalize;
        }
      }
      .entries-edit-delete {
        a,
        button {
          background: none;
          border: none;
          background-color: #e93d3d;
          color: #fff;
          display: inline-block;
          line-height: 1;
          margin: 0 5px;
          padding: 12px 25px;
          border-radius: 30px;
          text-transform: uppercase;
          font-weight: 500;
          /*&:hover {
            background-color: $theme-color;
          }*/
          &.edit {
            background-color: $theme-color;
          /*  &:hover {
              background-color: $theme-color;
            }*/
          }
        }
      }
    }
  }
}


  .Noord {
    padding: 30px 20px;
    margin-bottom: 0;
  }

  #view-orders {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
