.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: nowrap;
  //padding: 0px 70px;
  margin-top:15px;
  @media #{$xs-layout} {
    position:relative;
    width:auto;
    margin-top:0px;
    padding:  0px 0px ;
  }
}

.bold-placeholder::placeholder {
  font-weight: bold;
}


.header-dropdown {
  position: absolute;
  top: 40px;
  z-index: 100;
  width: 250px;
  display: flex;
  animation: fadeInDown 0.3s ease-in;

  @media #{$xs-layout} {
    z-index: 9;
  } 
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.dropdown-search-button {
  background: none;
  border: none;
  position: absolute;
  top: 7px;
  right: 10px;
}

.dropdown-search-icon {
  color: #d6d6d6;
}

.dropdown-search-input {
  border-radius: 30px;
  border-color: #d6d6d6;
  border-width: 1px;
  font-family: Poppins, roboto;
  &:focus {
    background: #eceff8 !important;
  }
}

.header-search-button {
  background: none;
  border: none;
}

.header-search-icon {
  color: #555252;
}

.dropdown-close-button {
    background: none;
    border: none;
    position: absolute;
    right: -10px;
    top: -15px;
    color: rgb(253, 63, 63)
}
