/*------ 16. Breadcrumb style  --------*/
@keyframes fadeGradient {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}



.breadcrumb-content {
  position: relative;
  z-index: 2;
  width:100%;
  ul {
    li {
      display: inline-block;
      font-size: 15px;
      font-weight: 500;
      color: #5b5858;
      margin-right: 18px;
      text-transform: uppercase;
      position: relative;
      &::before {
        position: absolute;
        width: 18px;
        height: 1px;
        background-color: #5b5858;
        content: "";
        right: -21px;
        top: 12px;
        z-index: 99;
        transform: rotate(115deg);
      }
      &:last-child::before {
        display: none;
      }
      a {
        color: #5b5858;
        &:hover {
          color: $theme-color;
        }
      }
    }
  }

  span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
    height: 300px;
    & > a {
      line-height: 50px;
      display: flex;
      align-items: center;
      flex-wrap:wrap;
      height: 200px;
      font-size: 42px;
      font-weight: 600;
      text-transform: uppercase;
      font-family: 'Poppins', sans-serif; /* Use a custom font if desired */
      color: black;
      background-image: linear-gradient(270deg, black 0%, $theme-color 50%, black 100%); /* Add a gradient color */
      background-size: 200% auto; /* Increase the size of the background gradient */
      -webkit-background-clip: text;
      background-clip: text; /* Clip the background to the text */
      color: transparent !important; /* Make the text color transparent to show the gradient */
      animation: fadeGradient 7s infinite; /* Add the animation */
      @media #{$md-layout} {
        margin-top: 0;
      }
      @media #{$xs-layout} {
        font-size: 28px;
      }
    }
    & > span {
      & > a {
        font-weight: 500;
        color: #c5c0c0;
        &:hover {
          color: $theme-color;
        }
      }

      & > span {
        margin-right: 0;
        margin: 0 15px;
      }
    }

  
  }
}


.breadcrumb-area {
  height: 300px;
  display: flex;
  align-items: center;
  position: relative; /* Add this line */
  @media #{$md-layout} {
    margin-top: 0;
  }
  @media #{$xs-layout} {
    height: 150px;
  }

}

.breadcrumb-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.669); /* Adjust the 0.5 value for desired transparency */
  z-index: 1; /* Add a z-index value to position the overlay below the breadcrumb content */
}

