

// display products and blog in 2 columns
.mainContainer{
  display:flex;
  padding: 50px 50px;
}

.blog-results-wrapper {
  width: 50%;
  border: 2px solid #edebeb;
  border-width: 0px 2px 0px 0px;
  padding-right: 40px;
}

.product-results-wrapper {
  width: 50%;
}

.bloglist-container {
  display: flex;
  flex-wrap: wrap;
}

.productlist-container {
  display: flex;
  flex-wrap: wrap;
}

// display blog posts in 2 columns
.search-results-bloglist-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}

/* For screens larger than 576px (Small devices - landscape phones) */
@media (min-width: 1000px) {
  .search-results-bloglist-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

/* For screens larger than 768px (Medium devices - tablets) */
@media (min-width: 1400px) {
  .search-results-bloglist-wrapper {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}



// display products in 2 columns
.search-results-product-wrapper {
  flex: 0 0 50%;
  padding: 10px 15px;
  box-sizing: border-box;
}




// Display blog posts and products inline
.bloglist {
  display: flex;
}

.productlist {
  display: flex;
}



// center text for each column
.search-heading {
  font-size: 32px;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  padding-bottom: 0px;
  margin-bottom: 25px;
  margin-left: 50px;
  color: #333;
}

.search-heading::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 3px;
  background-color: #04AA6D;
}

.search-heading::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  height: 3px;
  background-image: linear-gradient(90deg, #04AA6D 0%, rgba(182, 244, 146, 0.7) 100%);
}