* {
    box-sizing: border-box;
  }
  
  
  .app-wrapper {
      display: flex;  
    flex-wrap: wrap;  
      align-items: center;
      justify-content: space-around;
    padding: 2rem;
    overflow: auto;
    width: 100%;
  }
  
  .card-1 {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 1px 1px 10px 1px #ddd;
    margin-bottom: 1rem;
      max-width: 300px;
    padding: 1rem 0;
    position: relative;
    width: 100%;
    text-align: center;
    
    .most-popular {
      font-size: 15px;
      font-weight: 700;
      background-color: #1abc9c;
      color: #fff;
      padding: .25em 1rem;
      border-radius: 15px;
      text-transform: uppercase;
    }
      
    &.free {
     border-top: 6px solid #1abc9c; 
    }

    &.Prime .card-description p {
      font-weight: bold;
    }

    &.Prime .card-features li:nth-child(1) {
      font-weight: bold;
    }
    
    &.basic {
     border-top: 6px solid #3498db; 
    }
    
    &.medium {
     border-top: 6px solid silver; 
    }
    
    &.pro {
     border-top: 6px solid #f1c40f; 
    }
  }
  
  .card {
    &-description,
    &-billing,
    &-features,
    &-action {
      padding: 15px;
    }
    
    &-description {
      h2 { 
        font-size: 26px;
        margin-bottom: .5rem;
      }
    }
    
    &-billing {
      background-color: #ecf0f1;
      
      p {
        margin-bottom: .5rem;
      }
      
      .price {
        font-size: 32px;
        font-weight: 700;
      }
    }
    
    &-features {
      min-height: 150px;
      
      ul li {
        margin-bottom: .5rem;
      }
    }
    
    &-action {
      > button {
        border: 3px solid #04AA6D;
        background-color: 04AA6D;
        color: #fff;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        padding: .5rem 0;
        width: 100%;
        transition: .3s ease all;
      }
      
      > button:hover {
        transition: .3s ease all;
        background-color: #079c65;
        border-color: #079c65;
        color: #fff;
      }
    }
  }

.register-popup-title { 
  margin-bottom: 40px;
}

  @media #{$xxs-layout} {
    .app-wrapper {
      display: flex;  
    flex-wrap: wrap;  
      align-items: center;
      justify-content: space-around;
      width: 100%;
      overflow:visible;
    //padding: 2rem;
  }
  }

  @media #{$xs-layout} {
    .app-wrapper {
      display: flex;  
    flex-wrap: wrap;  
      align-items: center;
      justify-content: space-around;
    height: auto;
    //padding: 2rem;
    width: 100%;
    overflow:visible;
    position: relative;
    left:5px;
  }
  }

  
  @media #{$sm-layout} {
    .app-wrapper {
      display: flex;  
    flex-wrap: wrap;  
      align-items: center;
      justify-content: space-around;
    height: auto;
    //padding: 2rem;
    width: 100%;
    overflow:visible;
    position: relative;
    left:5px;
  }
  }

  @media #{$md-layout} {
    .app-wrapper {
      display: flex;  
    flex-wrap: wrap;  
      align-items: center;
      justify-content: space-around;
    height: auto;
    //padding: 2rem;
    width: 100vh;
    overflow:auto;
    position: relative;
    left:5px;
  }
  }

  @media #{$lg-layout} {
    .app-wrapper {
      display: flex;  
    flex-wrap: wrap;  
      align-items: center;
      justify-content: space-around;
    height: auto;
    //padding: 2rem;
    width: 100vh;
    overflow:auto;
    position: relative;
  }
  }