


.highlight {
    background-color: yellow;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.btn-category {
    cursor: pointer;
    margin: 0 10px;
    font-size: 20px;
    padding: 15px;
    border-radius: 30px;

}

.btn-category-selected {
    font-size: 24px;
    font-weight: 500;
    background: $theme-color;
    color: white;
    padding: 15px;
    cursor: pointer;
    margin: 0 10px;
    transition: all 0.2s ease-in-out;
    animation: fadein 0.2s;
    border-radius: 30px;
}

.blog-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: initial;
    flex: 0 0 33.333333% !important;
}

.no-product-found {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}



.no-product-found-button{
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 10px 30px;
    transition: 0.3s;
    background-color: $theme-color;
    border:1px solid $theme-color;
    border-radius:30px;
    width:150px;
    text-align: center;
}

.no-product-found-button-p{
    color:white;
    transition: all 0.3s ease-in-out ;

 &:hover{
    color:black;
 }

}

.no-blogs-found-button{
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 10px 30px;
    transition: 0.3s;
    background-color: $theme-color;
    border: 1px solid $theme-color;
    border-radius: 30px;
    width: auto;
    text-align: center;

    .no-blogs-found-button-p {
        color: white;
        transition: all 0.3s ease-in-out;

        &:hover {
            color: black;
        }

    }
}
.no-blogs-found{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    
}
