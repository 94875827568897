/*------ 24. login register Page  --------*/
.sign-up-from-login-div{
  text-align: center;
  cursor:pointer;
  font-size: 18px;
  font-weight: 500;
  border-radius: 30px;
  display: block;
  margin-bottom: 20px;
  padding: 16px 40px 17px;
  background-color: #fff;
  border: 1px solid black;
  &:hover{
      color: #fff !important;
      border: 1px solid $theme-color;
      background-color: $theme-color !important;

  }
}

.firstlastnamefields{
  display:flex;
  flex-direction:row;
  
}
.loginheadercontainer{
  display: flex;
  flex-direction: row;
  flex-wrap:nowrap;
  align-items: center;
  justify-content: center;
  flex:1 0.4;
  padding-bottom: 20px;
}

.indicator {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 5px;
}

.indicator-red {
  background-color: red;
}

.indicator-green {
  background-color: green;
}



.InterestsColumn2{
  position: relative;
  left:50%;
  top:-200px !important;
  @media #{$xs-layout} {
    position: relative;
    left:0%;
    top:0px !important;
    margin-bottom:50px;
  } 
}

.login-wrapper {
  .login-tab-list {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    .nav-item {
      position: relative;
      a {
        background: none;
        padding: 0;

        h4 {
          font-size: 25px;
          font-weight: 700;
          margin: 0 20px;
          text-transform: capitalize;
          transition: all 0.3s ease 0s;
        }
        &:last-child::before {
          display: none;
        }
      }
      a.active h4 {
        color: $theme-color;
      }

      &:before {
        background-color: #454545;
        bottom: 5px;
        content: "";
        height: 18px;
        margin: 0 auto;
        position: absolute;
        right: -2px;
        transition: all 0.4s ease 0s;
        width: 1px;
      }

      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }
  .login-form-container {
    max-width: 100%;
    margin: 0 auto;
    background: transparent none repeat scroll 0 0;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    padding: 80px;
    text-align: left;
    @media #{$lg-layout} {
      padding: 80px 50px;
    }
    @media #{$xs-layout} {
      padding: 40px 15px;
    }
    .login-form {
      form {
        input {
          background-color: transparent;
          border: 1px solid #ebebeb;
          color: #333;
          font-size: 14px;
          height: 45px;
          margin-bottom: 30px;
          padding: 0 15px;
        }
        input[type="checkbox"] {
          height: 15px;
          margin: 0;
          position: relative;
          top: 1px;
          width: 17px;
        }
        .button-box {
          .login-toggle-btn {
            padding: 10px 0 19px;
            label {
              color: #242424;
              font-size: 15px;
              font-weight: 400;
              @media #{$xs-layout} {
                font-size: 14px;
              }
            }
            a {
              color: #242424;
              float: right;
              font-size: 15px;
              @media #{$xs-layout} {
                font-size: 14px;
              }
              transition: all 0.3s ease 0s;
              &:hover {
                color: $theme-color;
              }
            }
          }
          button {
            background-color: #f2f2f2;
            border: medium none;
            color: #333;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
            padding: 11px 30px;
            text-transform: uppercase;
            transition: all 0.3s ease 0s;
            &:hover {
              background-color: $theme-color;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .login-form-container2 {
    max-width: 100%;
    padding:0px 80px;
    text-align: left;
    @media #{$lg-layout} {
      padding: 80px 50px;
    }
    @media #{$xs-layout} {
      max-width: 90%;
      padding: 40px 15px;
    }
    .login-form {
      form {
        input {
          background-color: transparent;
          border: 1px solid #ebebeb;
          color: #333;
          font-size: 14px;
          height: 45px;
          margin-bottom: 30px;
          padding: 0 15px;
        }
        input[type="checkbox"] {
          height: 15px;
          margin: 0;
          position: relative;
          top: 1px;
          width: 17px;
        }
        .button-box {
          .login-toggle-btn {
            padding: 10px 0 19px;
            label {
              color: #242424;
              font-size: 15px;
              font-weight: 400;
              @media #{$xs-layout} {
                font-size: 14px;
              }
            }
            a {
              color: #242424;
              float: right;
              font-size: 15px;
              @media #{$xs-layout} {
                font-size: 14px;
              }
              transition: all 0.3s ease 0s;
              &:hover {
                color: $theme-color;
              }
            }
          }
          button {
            background-color: #f2f2f2;
            border: medium none;
            color: #333;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
            padding: 11px 30px;
            text-transform: uppercase;
            transition: all 0.3s ease 0s;
            width:100%;
            &:hover {
              background-color: $theme-color;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.login-area {

  .container {
    max-width:100%
  }

  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}

